<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加人员</en-button>
      <en-dropdown @command="operation.options.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="upload">选择导入文件</en-dropdown-item>
          <en-dropdown-item command="download">下载导入模版</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :ajax="{
        action: 'GET /enocloud/common/branch',
        params: (params) => (params.payload = { pagingEnabled: false })
      }"
      :props="{ label: 'name', value: 'id' }"
      :method="table.get"
    ></tabs-maintain>

    <flex-box>
      <template #default="{ height }">
        <form-query :method="table.get" :model="form.data">
          <en-form-item label="姓名"> <en-input v-model="form.data.name"></en-input> </en-form-item>
          <en-form-item label="手机号"> <en-input v-model="form.data.cellphone"></en-input> </en-form-item>
          <en-form-item label="账号状态" prop="statusCode">
            <select-maintain
              v-model="form.data.statusCode"
              :ajax="{
                action: 'GET /enocloud/common/lookup/:lookupType',
                params(payload) {
                  payload.paths = ['URSTAT']
                }
              }"
              :props="{ label: 'message', value: 'code' }"
              value-key="id"
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </form-query>

        <flex-box>
          <template #default="{ height }">
            <en-table :data="table.data" :height="height" :paging="table.paging" pagination :method="table.get" :loading="table.loading">
              <en-table-column label="操作">
                <template #default="{ row }: { row: EnocloudAdminDefinitions['UserDto'] }">
                  <button-ajax link v-if="!row.ssoUser?.wechatUser" :method="table.operation.bind.click" :params="row">微信绑定</button-ajax>
                  <button-delete link v-if="row.ssoUser?.wechatUser" content="确定解绑吗？" :method="table.operation.unbind.click" :params="row">
                    微信解绑
                  </button-delete>
                  <button-ajax link :method="table.operation.reset.click" :params="row">重置密码</button-ajax>
                </template>
              </en-table-column>
              <en-table-column label="姓名">
                <template #default="{ row }: { row: EnocloudAdminDefinitions['UserDto'] }">
                  <button-ajax text :method="table.name.click" :params="row">{{ row.name }}</button-ajax>
                </template>
              </en-table-column>
              <en-table-column label="门店" prop="branch.shortName"></en-table-column>
              <en-table-column label="手机" prop="cellphone"></en-table-column>
              <en-table-column label="账号状态">
                <template #default="{ row }: { row: EnocloudAdminDefinitions['UserDto'] }">
                  <select-maintain
                    v-model="row.status"
                    :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['URSTAT']) }"
                    :props="{ label: 'message', value: '' }"
                    value-key="code"
                    @change="table.status.change(row)"
                  ></select-maintain>
                </template>
              </en-table-column>
              <en-table-column label="入职时间" prop="hiringDate"></en-table-column>
              <en-table-column label="权限角色">
                <template #default="{ row }: { row: EnocloudAdminDefinitions['UserDto'] }">
                  {{ row.roles?.map((role) => role.name).join(',') }}
                </template>
              </en-table-column>
            </en-table>
          </template>
        </flex-box>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑人员' : '添加人员'">
    <flex-box>
      <template #default="{ height }">
        <en-form :model="detail.form.data" :rules="detail.form.rules" :ref="setRef('detailForm')">
          <en-tabs v-model="detail.tabs.active">
            <en-tab-pane label="人员信息" name="user" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <en-form-item label="姓名" prop="name">
                <en-input v-model="detail.form.data.name"></en-input>
              </en-form-item>
              <en-form-item label="手机号码" prop="cellphone">
                <en-input v-model="detail.form.data.cellphone"></en-input>
              </en-form-item>
              <en-form-item label="账号状态" prop="status.code">
                <select-maintain
                  v-model="detail.form.data.status"
                  :ajax="{
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params(payload) {
                      payload.paths = ['URSTAT']
                    }
                  }"
                  :props="{ label: 'message', value: '' }"
                  value-key="code"
                  class="w-full"
                ></select-maintain>
              </en-form-item>
              <en-form-item label="账号角色权限">
                <select-maintain
                  v-model="detail.form.data.roles"
                  :ajax="{
                    action: 'GET /enocloud/admin/role',
                    params: (params, value) => {
                      params.payload = { name: value }
                    }
                  }"
                  :props="{ label: 'name', value: '' }"
                  multiple
                  remote
                  value-key="id"
                  class="w-full"
                ></select-maintain>
              </en-form-item>
              <en-form-item label="工号">
                <en-input v-model="detail.form.data.staffNo"></en-input>
              </en-form-item>
              <en-form-item label="身份证">
                <en-input v-model="detail.form.data.identityCard"></en-input>
              </en-form-item>
              <en-form-item label="学历">
                <select-maintain
                  v-model="detail.form.data.education"
                  :ajax="{
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => {
                      params.paths = ['EDUCATION']
                    }
                  }"
                  :props="{ value: 'name', label: 'name' }"
                  allow-create
                  class="w-full"
                ></select-maintain>
              </en-form-item>
              <en-form-item label="入职日期">
                <en-date-picker v-model="detail.form.data.hiringDate" value-format="YYYY-MM-DD" class="w-full"></en-date-picker>
              </en-form-item>
              <en-form-item label="籍贯">
                <en-input v-model="detail.form.data.nativePlace"></en-input>
              </en-form-item>
              <en-form-item label="紧急联系人">
                <en-input v-model="detail.form.data.contactName"></en-input>
              </en-form-item>
              <en-form-item label="联系人电话">
                <en-input v-model="detail.form.data.contactCellphone"></en-input>
              </en-form-item>
              <en-form-item label="客服QQ">
                <en-input v-model="detail.form.data.qq"></en-input>
              </en-form-item>
              <en-form-item>
                <template #label>
                  <div class="flex items-center justify-between">
                    <span>结算减免金额</span>
                    <span class="text-xs text-primary">允许减免的最大金额</span>
                  </div>
                </template>
                <en-input-number v-model="detail.form.data.discountLine" :min="0" class="w-full"></en-input-number>
              </en-form-item>
              <en-form-item label="所在部门">
                <select-maintain
                  v-model="detail.form.data.departmentName"
                  :ajax="{
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => {
                      params.paths = ['DPTMT']
                    }
                  }"
                  :props="{ label: 'name', value: 'name' }"
                  clearable
                  class="w-full"
                ></select-maintain>
              </en-form-item>
              <en-form-item label="备注">
                <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
              </en-form-item>
            </en-tab-pane>
            <en-tab-pane label="分配门店仓库" name="branch" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <en-form-item label="分配使用门店">
                <checkbox-maintain
                  v-model="detail.form.data.managedBranches"
                  :ajax="{
                    action: 'GET /enocloud/common/branch',
                    params: (params) => (params.payload = { pagingEnabled: false })
                  }"
                  :props="{ label: 'name', value: '' }"
                  value-key="id"
                  multiple
                ></checkbox-maintain>
              </en-form-item>
              <en-form-item label="分配使用仓库">
                <checkbox-maintain
                  v-model="detail.form.data.managedWarehouses"
                  :ajax="{
                    action: 'GET /enocloud/common/warehouse',
                    params(params) {
                      params.payload = { allBranches: true }
                    }
                  }"
                  :props="{ label: 'name', value: '' }"
                  value-key="id"
                  multiple
                ></checkbox-maintain>
              </en-form-item>
            </en-tab-pane>
          </en-tabs>
        </en-form>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>

  <en-upload :ref="setRef('uploadRef')" action="/enocloud/admin/user/import" :on-success="() => table.get()" class="absolute"></en-upload>
</template>

<script lang="ts">
import { h } from 'vue'
import { EnMessageBox } from '@enocloud/components'
import dayjs from 'dayjs'

export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        },
        options: {
          command(option: string) {
            switch (option) {
              case 'upload':
                this.refs.uploadRef.$el.querySelector('input').click()
                break
              case 'download':
                window.open('/enocloud/admin/user/template/export')
                break
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      form: {
        data: {
          name: '',
          cellphone: '',
          statusCode: 'A'
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/admin/user',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          reset: {
            action: 'POST /enocloud/admin/user/:userId/password'
          },
          bind: {
            action: 'GET /enocloud/admin/user/:userId/wechat/bind/url'
          },
          unbind: {
            action: 'POST /enocloud/admin/user/:userId/wechat/unbind'
          },
          update: {
            action: 'PUT /enocloud/admin/user',
            loading: true
          }
        },
        children: {
          operation: {
            bind: {
              async click(row: EnocloudAdminDefinitions['UserDto']) {
                const res = await this.table.bind({ paths: [row.id] })
                return EnMessageBox({
                  title: row.name,
                  center: true,
                  showConfirmButton: false,
                  message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
                })
              }
            },
            unbind: {
              async click(row: EnocloudAdminDefinitions['UserDto']) {
                await this.table.unbind({ paths: [row.id] })
                return this.table.get()
              }
            },
            reset: {
              async click(row: EnocloudAdminDefinitions['UserDto']) {
                await this.table.reset({ paths: [row.id] })
                return this.table.get()
              }
            }
          },
          name: {
            async click(row: EnocloudAdminDefinitions['UserDto']) {
              this.detail.form.init()
              this.detail.form.data.id = row.id
              this.detail.visible = true
              return this.detail.form.get()
            }
          },
          status: {
            async change(row: EnocloudAdminDefinitions['UserDto']) {
              await this.table.update({ payload: row })
              this.table.get()
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          tabs: {
            active: 'user'
          },
          form: {
            rules: {
              name: [{ required: true, message: '请填写姓名' }],
              cellphone: [{ required: true, message: '请填写手机号码' }],
              'status.code': { required: true, message: '请选择状态' }
            },
            data: {
              hiringDate: dayjs().format('YYYY-MM-DD')
            },
            ajax: {
              get: {
                action: 'GET /enocloud/admin/user/:userId',
                data: 'object',
                loading: true,
                init: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/admin/user',
                validate: true,
                loading: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/admin/user',
                validate: true,
                loading: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
